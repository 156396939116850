import { DialogModule } from '@angular/cdk/dialog';
import { LayoutModule } from '@angular/cdk/layout';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withFetch,
  withInterceptorsFromDi,
} from '@angular/common/http';
import {
  importProvidersFrom,
  inject,
  provideAppInitializer,
  provideExperimentalCheckNoChangesForDebug,
  provideZoneChangeDetection,
  type ApplicationConfig,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  NoPreloading,
  provideRouter,
  withComponentInputBinding,
  withDebugTracing,
  withInMemoryScrolling,
  withPreloading,
  withRouterConfig,
  withViewTransitions,
} from '@angular/router';
import { provideServiceWorker } from '@angular/service-worker';
import { provideTransloco, type Translation } from '@jsverse/transloco';
import { EffectsModule } from '@ngrx/effects';
import {
  NavigationActionTiming,
  StoreRouterConnectingModule,
} from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { NgxPermissionsModule } from 'ngx-permissions';
import { concatMap, first, type Observable } from 'rxjs';
import { environment } from '../environments/environment';
import { AuthModule } from './auth/auth.module';
import { ALL_AVAILABLE_LANGUAGES } from './core/consts/all-available-languages.consts';
import { DEFAULT_LANGUAGE } from './core/consts/languages.consts';
import { CoreModule } from './core/core.module';
import { ROUTES } from './core/core.routes';
import { CheckAuthorizationStatusInterceptor } from './core/interceptors/check-authorization-status.interceptor';
import type { CountryDto } from './core/models/countries.dto.models';
import type { LanguageDto } from './core/models/languages.dto.models';
import { CountriesService } from './core/services/countries.service';
import { LanguagesService } from './core/services/languages.service';
import { TranslocoHttpLoader } from './core/services/transloco-http-loader.service';
import { effects, metaReducers, reducers } from './core/store';

// TODO: implement an interceptor to abort GET requests when the user navigates away from the page

const configuration = {
  shouldUseFallbackTranslation:
    !environment.isLocal && !environment.isDev && !environment.isQa,
  router: {
    shouldEnableDebugTracing: false,
    shouldEnableViewTransitions: false,
  },
  zoneless: {
    shouldPerformChecksForCompatibility: {
      // NOTE: we should enable this from time to time to check for compatibility issues
      periodically: false,
      whenStable: true,
    },
  },
};

export const applicationConfiguration: ApplicationConfig = {
  providers: [
    provideRouter(
      ROUTES,
      withRouterConfig({
        paramsInheritanceStrategy: 'always',
      }),
      ...(configuration.router.shouldEnableDebugTracing ?
        [withDebugTracing()]
      : []),
      withInMemoryScrolling({
        scrollPositionRestoration: 'enabled',
      }),
      withPreloading(NoPreloading),
      ...(configuration.router.shouldEnableViewTransitions ?
        [withViewTransitions()]
      : []),
      withComponentInputBinding(),
    ),
    provideZoneChangeDetection({
      eventCoalescing: true,
      runCoalescing: true,
    }),
    ...((
      configuration.zoneless.shouldPerformChecksForCompatibility.whenStable ||
      configuration.zoneless.shouldPerformChecksForCompatibility.periodically
    ) ?
      [
        provideExperimentalCheckNoChangesForDebug({
          exhaustive: true,
          interval:
            (
              configuration.zoneless.shouldPerformChecksForCompatibility
                .periodically
            ) ?
              1000
            : undefined,
          useNgZoneOnStable:
            configuration.zoneless.shouldPerformChecksForCompatibility
              .whenStable,
        }),
      ]
    : []),
    importProvidersFrom(
      BrowserModule,
      BrowserAnimationsModule,
      CoreModule,
      AuthModule,
      LayoutModule,
      NgxPermissionsModule.forRoot(),
      DialogModule,
      StoreRouterConnectingModule.forRoot({
        navigationActionTiming: NavigationActionTiming.PostActivation,
      }),
      StoreModule.forRoot(reducers, {
        metaReducers,
        runtimeChecks: {
          strictStateImmutability: true,
          strictActionImmutability: true,
          strictStateSerializability: false,
          strictActionSerializability: false,
          strictActionWithinNgZone: true,
          strictActionTypeUniqueness: true,
        },
      }),
      EffectsModule.forRoot(effects),
      environment.production ?
        []
      : StoreDevtoolsModule.instrument({
          autoPause: true,
        }),
    ),
    provideHttpClient(withFetch(), withInterceptorsFromDi()),
    // TODO: translations (send error to Slack when a translation is missing?)
    provideTransloco({
      config: {
        availableLangs: ALL_AVAILABLE_LANGUAGES,
        defaultLang: DEFAULT_LANGUAGE,
        fallbackLang: DEFAULT_LANGUAGE,
        missingHandler: {
          // NOTE: it will use the first language set in the `fallbackLang` property
          useFallbackTranslation: configuration.shouldUseFallbackTranslation,
        },
        // TODO: enable when we get rid of the `LanguageReloadModalComponent`
        reRenderOnLangChange: false,
        prodMode: environment.production,
        flatten: {
          aot: environment.production,
        },
      },
      loader: TranslocoHttpLoader,
    }),
    provideAppInitializer((): Observable<CountryDto[]> => {
      return inject(CountriesService).initializeCountries();
    }),
    provideAppInitializer((): Observable<LanguageDto[]> => {
      return inject(LanguagesService).initializeLanguages();
    }),
    provideAppInitializer((): Observable<Translation> => {
      const languagesService = inject(LanguagesService);

      return languagesService.isLanguageSelectorAvailable$.pipe(
        first(),
        concatMap((isLanguageSelectorAvailable) => {
          const activeLanguage =
            (isLanguageSelectorAvailable ?
              languagesService.detectActiveLanguageFromLocalStorageOrBrowser()
            : undefined) ?? DEFAULT_LANGUAGE;

          languagesService.setActiveLanguage(activeLanguage);

          return languagesService.loadLanguage(activeLanguage);
        }),
      );
    }),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CheckAuthorizationStatusInterceptor,
      multi: true,
    },
    provideServiceWorker('ngsw-worker.js', {
      enabled: environment.production && !environment.isInCypress,
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
};

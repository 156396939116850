import {
  ApplicationRef,
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  NgZone,
  type AfterViewInit,
  type OnInit,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router, RouterOutlet } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { marker } from '@jsverse/transloco-keys-manager/marker';
import {
  catchError,
  concat,
  filter,
  first,
  fromEvent,
  interval,
  map,
  merge,
  of,
  skip,
  switchMap,
  tap,
  timeout,
} from 'rxjs';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../auth/services/auth.service';
import { ReloadThisPageModalComponent } from '../../../shared/components/reload-this-page-modal/reload-this-page-modal.component';
import { Roles } from '../../../shared/consts/roles.enum';
import { SharedFacade } from '../../../shared/store/shared.facade';
import { ERROR_DETAILS_KEY } from '../../consts/error.consts';
import { getErrorDetails } from '../../helpers/get-error-details.helper';
import { DialogService } from '../../services/dialog.service';
import { LoggerService, MessageLevel } from '../../services/logger.service';

// TODO: refactor

const serviceWorkerTag = '#SERVICE_WORKER';

const detailsKey = 'Details';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [RouterOutlet],
  selector: 'app-root-container',
  templateUrl: './app.container.html',
})
export class AppContainerComponent implements OnInit, AfterViewInit {
  readonly #applicationReference = inject(ApplicationRef);
  readonly #authService = inject(AuthService);
  readonly #destroyReference = inject(DestroyRef);
  readonly #dialog = inject(DialogService);
  readonly #logger = inject(LoggerService);
  readonly #ngZone = inject(NgZone);
  readonly #router = inject(Router);
  readonly #sharedFacade = inject(SharedFacade);
  readonly #swUpdate = inject(SwUpdate);

  readonly #ROUTES_THAT_SHOULD_NOT_DISPLAY_UPDATE_MESSAGE = [
    'register-supplier',
  ];

  ngOnInit(): void {
    this.#checkIfApplicationBecomesStableInReasonableTime();
    this.#initializeNetworkAccessTracking();
    this.#monitorServiceWorkerUpdates();
  }

  ngAfterViewInit(): void {
    this.#initializePendoIfEnabled();
  }

  #checkIfApplicationBecomesStableInReasonableTime(): void {
    this.#ngZone.runOutsideAngular(() => {
      this.#applicationReference.isStable
        .pipe(
          first((isStable) => isStable),
          timeout({
            // NOTE: 10 seconds
            first: 10_000,
            with: () => of(false),
          }),
          filter((isStable) => !isStable),
          tap(() => {
            // TODO: make sure this does not happen very often
            this.#logger.log({
              level: MessageLevel.Error,
              message: 'Application is not stable after 10 seconds',
              data: {},
            });
          }),
        )
        .subscribe();
    });
  }

  #initializeNetworkAccessTracking(): void {
    this.#ngZone.runOutsideAngular(() => {
      merge(
        of(navigator.onLine),
        fromEvent(globalThis, 'online').pipe(map(() => true)),
      )
        .pipe(
          filter((hasNetworkAccess) => hasNetworkAccess),
          skip(1),
          tap(() => {
            this.#ngZone.run(() => {
              this.#sharedFacade.enqueueSuccessSnackbar({
                translationKey: marker('shared.onlineMessage'),
              });
            });
          }),
          takeUntilDestroyed(this.#destroyReference),
        )
        .subscribe();
    });

    this.#ngZone.runOutsideAngular(() => {
      merge(
        of(navigator.onLine),
        fromEvent(globalThis, 'offline').pipe(map(() => false)),
      )
        .pipe(
          filter((hasNetworkAccess) => !hasNetworkAccess),
          tap(() => {
            this.#ngZone.run(() => {
              this.#sharedFacade.enqueueErrorSnackbar({
                translationKey: marker('shared.offlineMessage'),
              });
            });
          }),
          takeUntilDestroyed(this.#destroyReference),
        )
        .subscribe();
    });
  }

  // TODO: extract to a service
  #monitorServiceWorkerUpdates(): void {
    if (!this.#swUpdate.isEnabled) {
      return;
    }

    this.#checkForServiceWorkerUpdatesPeriodically();
    this.#listenForServiceWorkerVersionUpdatesEvents();
    this.#listenForServiceWorkerUnrecoverableStateEvents();
  }

  #checkForServiceWorkerUpdatesPeriodically(): void {
    this.#ngZone.runOutsideAngular(() => {
      concat(
        this.#applicationReference.isStable.pipe(
          first((isStable) => isStable),
          timeout({
            // NOTE: 1 minute
            first: 60_000,
            with: () => of(false),
          }),
        ),
        // NOTE: 1 hour
        interval(3_600_000),
      )
        .pipe(
          switchMap(() => this.#swUpdate.checkForUpdate()),
          catchError((error: unknown) => {
            this.#logger.log({
              level: MessageLevel.Error,
              message: `Error while checking for application update ${serviceWorkerTag}`,
              data: {
                [ERROR_DETAILS_KEY]: getErrorDetails(error),
              },
            });

            return of(false);
          }),
          takeUntilDestroyed(this.#destroyReference),
        )
        .subscribe();
    });
  }

  #listenForServiceWorkerVersionUpdatesEvents(): void {
    this.#ngZone.runOutsideAngular(() => {
      this.#swUpdate.versionUpdates
        .pipe(
          tap((versionEvent) => {
            if (
              this.#ROUTES_THAT_SHOULD_NOT_DISPLAY_UPDATE_MESSAGE.some(
                (route) => this.#router.url.includes(route),
              )
            ) {
              return;
            }

            switch (versionEvent.type) {
              case 'NO_NEW_VERSION_DETECTED': {
                // NOTE: do nothing

                break;
              }
              case 'VERSION_DETECTED': {
                this.#logger.log({
                  level: MessageLevel.Info,
                  message: `New application version detected ${serviceWorkerTag}`,
                  data: {
                    [detailsKey]: {
                      versionEvent,
                    },
                  },
                });

                break;
              }
              case 'VERSION_READY': {
                this.#logger.log({
                  level: MessageLevel.Info,
                  message: `New application version is ready ${serviceWorkerTag}`,
                  data: {
                    [detailsKey]: {
                      versionEvent,
                    },
                  },
                });

                this.#ngZone.run(() => {
                  if (!this.#authService.checkIfLocalBrowserDataIsValid()) {
                    // QUESTION: should we show a different message here?
                    this.#authService.removeInformationFromStorage();

                    void this.#router.navigate(['/login']);
                  }

                  const loggedInUserRole =
                    this.#authService.getLoggedInUserRole();

                  if (
                    loggedInUserRole === Roles.AUDITOR ||
                    loggedInUserRole === Roles.SUPPLIER
                  ) {
                    this.#dialog.open(ReloadThisPageModalComponent, {
                      width: '400px',
                    });
                  } else {
                    this.#sharedFacade.enqueueInfoSnackbar({
                      translationKey: marker('shared.versionReadyMessage'),
                      // TODO: move to a constant
                      priority: 1_000_000,
                      canBeDismissed: false,
                    });
                  }
                });

                break;
              }
              case 'VERSION_INSTALLATION_FAILED': {
                this.#logger.log({
                  level: MessageLevel.Error,
                  message: `Error while installing application update ${serviceWorkerTag}`,
                  data: {
                    [detailsKey]: {
                      versionEvent,
                    },
                  },
                });

                this.#ngZone.run(() => {
                  this.#sharedFacade.enqueueInfoSnackbar({
                    translationKey: marker(
                      'shared.versionInstallationFailedMessage',
                    ),
                    // TODO: move to a constant
                    priority: 1_000_001,
                    canBeDismissed: false,
                  });
                });

                break;
              }
              default: {
                this.#logger.log({
                  level: MessageLevel.Error,
                  message: `Unknown service worker version event ${serviceWorkerTag}`,
                  data: {
                    [detailsKey]: {
                      versionEvent,
                    },
                  },
                });

                break;
              }
            }
          }),
          takeUntilDestroyed(this.#destroyReference),
        )
        .subscribe();
    });
  }

  #listenForServiceWorkerUnrecoverableStateEvents(): void {
    this.#ngZone.runOutsideAngular(() => {
      this.#swUpdate.unrecoverable
        .pipe(
          tap((unrecoverableStateEvent) => {
            this.#logger.log({
              level: MessageLevel.Error,
              message: `Unrecoverable state of service worker ${serviceWorkerTag}`,
              data: {
                [detailsKey]: {
                  unrecoverableStateEvent,
                },
              },
            });

            this.#ngZone.run(() => {
              this.#dialog.open(ReloadThisPageModalComponent, {
                width: '400px',
              });
            });
          }),
          takeUntilDestroyed(this.#destroyReference),
        )
        .subscribe();
    });
  }

  #initializePendoIfEnabled(): void {
    if (!environment.usePendo) {
      return;
    }

    this.#ngZone.runOutsideAngular(() => {
      pendo.initialize();
    });
  }
}

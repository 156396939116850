import { bootstrapApplication } from '@angular/platform-browser';
import { enableMapSet } from 'immer';
import { applicationConfiguration } from './app/application-configuration.consts';
import { AppContainerComponent } from './app/core/containers/app/app.container';

enableMapSet();

// eslint-disable-next-line unicorn/prefer-top-level-await
bootstrapApplication(AppContainerComponent, applicationConfiguration).catch(
  (error: unknown) => {
    console.error(error);
  },
);

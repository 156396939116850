<app-modal
  *transloco="let t"
  [canBeClosed]="false"
  [shouldShowCancelButton]="false"
  [version]="'2.0.0'"
>
  <app-modal-title>
    {{ t('shared.reloadThisPageModalTitle') }}
  </app-modal-title>

  <app-modal-content>
    {{ t('shared.reloadThisPageModalContent') }}
  </app-modal-content>

  <app-modal-actions>
    <app-button (click)="onReloadButtonClick()" appModalAction>
      {{ t('shared.reload') }}
    </app-button>
  </app-modal-actions>
</app-modal>

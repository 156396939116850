import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { TranslocoDirective } from '@jsverse/transloco';
import { ModalActionDirective } from '../../directives/modal-action/modal-action.directive';
import { ButtonComponent } from '../button/button.component';
import { ModalActionsComponent } from '../modal/modal-actions/modal-actions.component';
import { ModalContentComponent } from '../modal/modal-content/modal-content.component';
import { ModalTitleComponent } from '../modal/modal-title/modal-title.component';
import { ModalComponent } from '../modal/modal.component';

@Component({
  selector: 'app-reload-this-page-modal',
  templateUrl: './reload-this-page-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ButtonComponent,
    ModalActionDirective,
    ModalActionsComponent,
    ModalComponent,
    ModalContentComponent,
    ModalTitleComponent,
    TranslocoDirective,
  ],
})
export class ReloadThisPageModalComponent {
  readonly #document = inject(DOCUMENT);

  onReloadButtonClick(): void {
    this.#document.location.reload();
  }
}

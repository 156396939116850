import { ErrorHandler, inject, Injector, NgModule } from '@angular/core';
import { setAppInjector } from './helpers/set-app-injector.helper';
import { GlobalErrorHandler } from './services/global-error-handler.service';

@NgModule({
  providers: [
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
  ],
})
export class CoreModule {
  constructor() {
    setAppInjector(inject(Injector));
  }
}
